<template>
  <div>
    
    

  </div>
</template>

<script>
// -- import something here --

export default {

  data: () => ({
  }),

  mounted() {
    console.log("welcome home");
  },

  methods: {
    
  },

};
</script>

<style lang="scss">
@import '~scss/main';


</style>


